import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, TextField, Button, Typography, Select, MenuItem, FormControl, InputLabel, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import { AddCircle, RemoveCircle, Delete, Print } from '@mui/icons-material';
import http from '../../services/httpService';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import jwt_decode from 'jwt-decode';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const OrderForm = ({ isReadOnly }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { authState } = useContext(AuthContext);

  const orderRef = useRef(); // Ref to the order form for PDF generation

  let currentUserID = null;
  let currentUserRoleID=null;
  if (authState.token) {
    const decodedToken = jwt_decode(authState.token);
    currentUserID = decodedToken.id;
    currentUserRoleID = String(decodedToken.role_id);
  }

  const [order, setOrder] = useState({
    user_id: currentUserID || '',
    status: 'Pending',
    order_items: location.state?.cart || [{ product_id: '', quantity: 1, price: 0 }],
    billing_address: '',
    shipping_address: '',
    use_separate_shipping: false,
  });

  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (id) {
      fetchOrder();
    }
    fetchUsers();
    fetchProducts();
  }, [id]);

  useEffect(() => {
    updateTotal();
  }, [order.order_items]);

  const fetchOrder = async () => {
    try {
      const response = await http.get(`/orders/${id}`);
      setOrder(response.data);
    } catch (error) {
      console.error('Failed to fetch order', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await http.get('/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Failed to fetch users', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await http.get('/products');
      setProducts(response.data);
    } catch (error) {
      console.error('Failed to fetch products', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setOrder({
      ...order,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleOrderItemChange = (index, e) => {
    const { name, value } = e.target;
    const newOrderItems = [...order.order_items];
    newOrderItems[index][name] = value;
    setOrder({ ...order, order_items: newOrderItems });
  };

  const updateTotal = () => {
    const newTotal = order.order_items.reduce((acc, item) => acc + item.quantity * item.price, 0);
    setTotal(newTotal);
  };

  const handleQuantityChange = (index, delta) => {
    const newOrderItems = [...order.order_items];
    newOrderItems[index].quantity = Math.max(1, newOrderItems[index].quantity + delta);
    setOrder({ ...order, order_items: newOrderItems });
  };

  const addOrderItem = () => {
    setOrder({ ...order, order_items: [...order.order_items, { product_id: '', quantity: 1, price: 0 }] });
  };

  const removeOrderItem = (index) => {
    const newOrderItems = [...order.order_items];
    newOrderItems.splice(index, 1);
    setOrder({ ...order, order_items: newOrderItems });
  };

  const handleProductChange = (index, e) => {
    const product_id = e.target.value;
    const product = products.find(p => p.product_id === product_id);
    const newOrderItems = [...order.order_items];
    newOrderItems[index] = {
      ...newOrderItems[index],
      product_id,
      price: product.price,
    };
    setOrder({ ...order, order_items: newOrderItems });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (order.order_items.length === 0) {
      setError('At least one item must be present in the order.');
      return;
    }
    const url = id ? `/orders/${id}` : '/orders';
    const method = id ? 'put' : 'post';

    try {
      await http[method](url, order);
      navigate('/orders');
    } catch (error) {
      console.error('Failed to save order', error);
    }
  };

  const handlePrint = () => {
    const input = orderRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      const padding = 10; // Define a padding of 10mm
      pdf.addImage(imgData, 'PNG', padding, padding, pdfWidth - padding * 2, pdfHeight - padding * 2);
      pdf.save('order.pdf');
    });
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - 240px)` },
        ml: { sm: `240px` },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography 
          variant="h5" 
          gutterBottom 
          sx={{ 
            color: '#008080',
            fontWeight: 'bold',
            textTransform: 'uppercase'
          }}
        >
           {id ? 'Edit Order' : 'Add Order'}
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          startIcon={<Print />}
          onClick={handlePrint}
          sx={{ ml: 2 }}
        >
          Print to PDF
        </Button>
      </Box>

      <form ref={orderRef} onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal" disabled={isReadOnly}>
          <InputLabel>User</InputLabel>
          <Select
            label="User"
            name="user_id"
            value={order.user_id}
            onChange={!isReadOnly ? handleInputChange : undefined}
            required
          >
            {users.map((user) => (
              <MenuItem key={user.user_id} value={user.user_id}>
                {`${user.first_name} ${user.last_name} (${user.email})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal" disabled={isReadOnly || currentUserRoleID =="4" }>
          <InputLabel>Status</InputLabel>
          <Select
            label="Status"
            name="status"
            value={order.status}
            onChange={!isReadOnly ? handleInputChange : undefined}
          >
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="Cancelled">Cancelled</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Billing Address"
          name="billing_address"
          variant="outlined"
          margin="normal"
          fullWidth
          value={order.billing_address}
          onChange={!isReadOnly ? handleInputChange : undefined}
          required
          disabled={isReadOnly}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={order.use_separate_shipping}
              onChange={!isReadOnly ? handleInputChange : undefined}
              name="use_separate_shipping"
              color="primary"
              disabled={isReadOnly}
            />
          }
          label="Use separate shipping address"
        />

        {order.use_separate_shipping && (
          <Box sx={{ mt: 2 }}>
            <TextField
              label="Shipping Address"
              name="shipping_address"
              variant="outlined"
              margin="normal"
              fullWidth
              value={order.shipping_address}
              onChange={!isReadOnly ? handleInputChange : undefined}
              required
              disabled={isReadOnly}
            />
          </Box>
        )}

        <Typography variant="h6" gutterBottom>
          Order Items
        </Typography>
        {order.order_items.map((item, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <FormControl fullWidth margin="normal" disabled={isReadOnly}>
              <InputLabel>Product</InputLabel>
              <Select
                name="product_id"
                value={item.product_id}
                onChange={!isReadOnly ? (e) => handleProductChange(index, e) : undefined}
              >
                {products.map((product) => (
                  <MenuItem key={product.product_id} value={product.product_id}>
                    {product.name} - ${product.price}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {!isReadOnly && <IconButton color="secondary" onClick={() => handleQuantityChange(index, -1)}>
                <RemoveCircle />
              </IconButton>}
              <TextField
                label="Quantity"
                name="quantity"
                type="number"
                variant="outlined"
                margin="normal"
                sx={{ width: '60px', textAlign: 'center' }}
                value={item.quantity}
                onChange={!isReadOnly ? (e) => handleOrderItemChange(index, e) : undefined}
                required
                disabled={isReadOnly}
              />
              {!isReadOnly && <IconButton color="primary" onClick={() => handleQuantityChange(index, 1)}>
                <AddCircle />
              </IconButton>}
            </Box>
            <Typography variant="body2" sx={{ marginLeft: 2 }}>
              Subtotal: ${(item.quantity * item.price).toFixed(2)}
            </Typography>
            {!isReadOnly && (
              <IconButton color="error" onClick={() => removeOrderItem(index)}>
                <Delete />
              </IconButton>
            )}
          </Box>
        ))}
        {!isReadOnly && (
          <Button variant="outlined" color="primary" onClick={addOrderItem} startIcon={<AddCircle />} sx={{ mt: 2 }}>
            Add Product
          </Button>
        )}

        <Typography variant="h6" sx={{ marginTop: 2 }}>
          Total: ${total.toFixed(2)}
        </Typography>

        {error && (
          <Typography color="error" variant="body2" sx={{ marginTop: 2 }}>
            {error}
          </Typography>
        )}

        {!isReadOnly && (
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 3 }}>
            {id ? 'Update Order' : 'Add Order'}
          </Button>
        )}
      </form>
    </Box>
  );
};

export default OrderForm;

import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import http from '../services/httpService'; // Make sure to adjust the path to your http service
import AuthContext from '../context/AuthContext';
import jwt_decode from 'jwt-decode';

const Dashboard = () => {
  const { authState, logout } = useContext(AuthContext);
  const [stats, setStats] = useState({
    totalProducts: 0,
    totalRecipes: 0,
    totalUsers: 0,
    totalOrders: 0,
    completedOrders: 0,
    pendingOrders: 0,
    cancelledOrders: 0,
    returnedOrders: 0,
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await http.get('/dashboard/stats');
        setStats(response.data);
      } catch (error) {
        console.error('Failed to fetch dashboard stats', error);
      }
    };

    fetchStats();
  }, []);

  let roleID="";
  if(authState.token){
    const decodedToken = jwt_decode(authState.token);
    roleID = String(decodedToken.role_id);
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - 240px)` },
        ml: { sm: `240px` },
        mt: { sm: 0 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Typography 
          variant="h5" 
          gutterBottom 
          sx={{ 
            color: '#008080',
            fontWeight: 'bold',
            textTransform: 'uppercase'
          }}
      >
           Dashboard
      </Typography>
      <Grid container spacing={3}>
        {roleID !="4" && 
          <>
            <Grid item xs={12} sm={6} md={3}>
              <Paper style={{ padding: '20px', textAlign: 'center' }}>
                <Typography variant="h6">Total Products</Typography>
                <Typography variant="h4">{stats.totalProducts}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper style={{ padding: '20px', textAlign: 'center' }}>
                <Typography variant="h6">Total Recipes</Typography>
                <Typography variant="h4">{stats.totalRecipes}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper style={{ padding: '20px', textAlign: 'center' }}>
                <Typography variant="h6">Total Users</Typography>
                <Typography variant="h4">{stats.totalUsers}</Typography>
              </Paper>
            </Grid>
          </>
        }
        <Grid item xs={12} sm={6} md={3}>
          <Paper style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h6">Total Orders</Typography>
            <Typography variant="h4">{stats.totalOrders}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h6">Completed Orders</Typography>
            <Typography variant="h4">{stats.completedOrders}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h6">Pending Orders</Typography>
            <Typography variant="h4">{stats.pendingOrders}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h6">Cancelled Orders</Typography>
            <Typography variant="h4">{stats.cancelledOrders}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h6">Returned Orders</Typography>
            <Typography variant="h4">{stats.returnedOrders}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
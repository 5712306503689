// src/services/imageRecognitionService.js
import axios from 'axios';

// Replace this with your actual API URL
const API_URL = process.env.REACT_APP_IMAGE_RECOGNITION_API_URL || 'https://your-api-url.com';

export const scanImageForShelfLife = async (imageFile) => {
  try {
    let formData = new FormData();

    // Check if the imageFile is a base64 string (from the webcam) or a File object (from file upload)
    if (typeof imageFile === 'string') {
      // If the image is a base64 string, convert it to a Blob object
      const byteString = atob(imageFile.split(',')[1]);
      const mimeString = imageFile.split(',')[0].split(':')[1].split(';')[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const intArray = new Uint8Array(arrayBuffer);
      
      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([intArray], { type: mimeString });
      formData.append('image', blob, 'image.jpg');
    } else {
      // If the image is a File object, append it directly
      formData.append('image', imageFile);
    }

    // Send the formData to your backend API
    const response = await axios.post(`${API_URL}/scan`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Assume the response data contains shelf life information
    return response.data;
  } catch (error) {
    console.error('Error scanning image:', error);
    throw error;
  }
};

import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import AuthContext from '../../context/AuthContext';
import http from '../../services/httpService';

const UserList = () => {
  const { authState } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await http.get('/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Failed to fetch users', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await http.delete(`/users/${id}`);
      fetchUsers(); // Refresh the list after deletion
    } catch (error) {
      console.error('Failed to delete user', error);
    }
  };

  const filteredUsers = users.filter((user) =>{
      console.log(user);
      return (
        String(user.first_name).toLowerCase().includes(searchQuery.toLowerCase()) 
        ||
        String(user.last_name).toLowerCase().includes(searchQuery.toLowerCase()) 
        ||
        String(user.email).toLowerCase().includes(searchQuery.toLowerCase()) 
      );
    }
  );

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - 240px)` }, // Adjust width for larger screens
        ml: { sm: `240px` }, // Margin left for larger screens to accommodate the drawer
      }}
    >
      <Typography 
          variant="h5" 
          gutterBottom 
          sx={{ 
            color: '#008080',
            fontWeight: 'bold',
            textTransform: 'uppercase'
          }}
        >
           User List
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Button variant="contained" color="primary" href="/users/new">
          Add User
        </Button>

        <TextField
          label="Search Users"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>

      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.user_id}>
                <TableCell>{user.first_name}</TableCell>
                <TableCell>{user.last_name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{new Date(user.date_of_birth).toLocaleDateString()}</TableCell>
                <TableCell>{user.role.name}</TableCell>
                <TableCell>
                  <IconButton color="primary" href={`/users/edit/${user.user_id}`}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDelete(user.user_id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserList;

import React, { useState, useEffect,useContext } from 'react';
import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField } from '@mui/material';
import { Edit, Topic } from '@mui/icons-material';
import http from '../../services/httpService';
import AuthContext from '../../context/AuthContext';
import jwt_decode from 'jwt-decode';

const OrderList = ({isReadOnly}) => {
  const [orders, setOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const { authState } = useContext(AuthContext);

  if(authState.token){
    const decodedToken = jwt_decode(authState.token);
    let roleID=String(decodedToken.role_id);

    if(roleID =="4"){
       isReadOnly=true;
    }
  }

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    setFilteredOrders(
      orders.filter(order =>
        order.order_id.toString().includes(searchQuery) ||
        order.user_id.toString().includes(searchQuery) ||
        order.status.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, orders]);

  const fetchOrders = async () => {
    try {
      const response = await http.get('/orders');
      setOrders(response.data);
      setFilteredOrders(response.data);
    } catch (error) {
      console.error('Failed to fetch orders', error);
    }
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - 240px)` }, // Adjust width for larger screens
        ml: { sm: `240px` }, // Margin left for larger screens to accommodate the drawer
      }}
    >
      <Typography 
          variant="h5" 
          gutterBottom 
          sx={{ 
            color: '#008080',
            fontWeight: 'bold',
            textTransform: 'uppercase'
          }}
        >
           Order List
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
      {(isReadOnly?<></>:<Button variant="contained" color="primary" href="/orders/new">
          Add Order
        </Button>)}
        
        <TextField
          label="Search Orders"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Total Price</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOrders.map((order) => (
              <TableRow key={order.order_id}>
                <TableCell>{order.order_id}</TableCell>
                <TableCell>{order.user.first_name} {order.user.last_name}</TableCell>
                <TableCell>${Number.parseFloat(order.total_price).toFixed(2)}</TableCell>
                <TableCell>{order.status}</TableCell>
                <TableCell>{new Date(order.created_at).toLocaleDateString()}</TableCell>
                <TableCell>
                {(isReadOnly ?<IconButton color="primary" href={`/orders/view/${order.order_id}`}>
                    <Topic />
                  </IconButton>:<IconButton color="primary" href={`/orders/edit/${order.order_id}`}>
                    <Edit />
                  </IconButton>)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OrderList;

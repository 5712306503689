import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Select, Grid, MenuItem, FormControl, InputLabel, IconButton, Avatar } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import http from '../../services/httpService';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

const RecipeForm = () => {
  const { id } = useParams(); // Get the id from the URL
  const navigate = useNavigate();
  const [recipe, setRecipe] = useState({
    title: '',
    description: '',
    preparation_time: '',
    cooking_time: '',
    servings: '',
    difficulty_level: 'Easy',
    instructions: '',
    recipe_products: [{ product_id: '', quantity: '', unit_of_measure: '' }]
  });

  const [productList, setProductList] = useState([]);
  const [imagePreview, setImagePreview] = useState('');
  const [selectedImage, setSelectedImage] = useState(null); // Store the selected image file

  useEffect(() => {
    fetchProducts();
    if (id) {
      fetchRecipe();
    }
  }, [id]);

  const fetchRecipe = async () => {
    try {
      const response = await http.get(`/recipes/${id}`);
      setRecipe(response.data);
      if (response.data.image_url) {
        setImagePreview(response.data.image_url);
      }
    } catch (error) {
      console.error('Failed to fetch recipe', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await http.get('/products');
      setProductList(response.data);
    } catch (error) {
      console.error('Failed to fetch products', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRecipe({ ...recipe, [name]: value });
  };

  const handleInstructionsChange = (value) => {
    setRecipe({ ...recipe, instructions: value });
  };

  const handleProductChange = (index, event) => {
    const { name, value } = event.target;
    const newProducts = [...recipe.recipe_products];
    newProducts[index][name] = value;
    setRecipe({ ...recipe, recipe_products: newProducts });
  };

  const addProduct = () => {
    setRecipe({ ...recipe, recipe_products: [...recipe.recipe_products, { product_id: '', quantity: '', unit_of_measure: '' }] });
  };

  const removeProduct = (index) => {
    const newProducts = [...recipe.recipe_products];
    newProducts.splice(index, 1);
    setRecipe({ ...recipe, recipe_products: newProducts });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file); // Store the image file
      setImagePreview(URL.createObjectURL(file)); // Create a temporary preview URL
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    for (const key in recipe) {
      formData.append(key, recipe[key]);
    }

    // Append the selected image file if it exists
    if (selectedImage) {
      formData.append('image', selectedImage);
    }

    const url = id ? `/recipes/${id}` : '/recipes';
    const method = id ? 'put' : 'post';

    try {
      await http[method](url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure the content type is set to multipart
        },
      });
      navigate('/recipes'); // Navigate after a successful save
    } catch (error) {
      console.error('There was an error saving the recipe!', error);
    }
  };

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - 240px)` }, // Adjust width for larger screens
        ml: { sm: `240px` }, // Margin left for larger screens to accommodate the drawer
      }}
    >
      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ 
          color: '#008080',
          fontWeight: 'bold',
          textTransform: 'uppercase'
        }}
      >
        {id ? 'Edit Recipe' : 'Add Recipe'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Recipe Title"
          name="title"
          variant="outlined"
          margin="normal"
          fullWidth
          value={recipe.title}
          onChange={handleInputChange}
          required
        />
        <TextField
          label="Description"
          name="description"
          variant="outlined"
          margin="normal"
          fullWidth
          multiline
          rows={4}
          value={recipe.description}
          onChange={handleInputChange}
        />
        <TextField
          label="Preparation Time (minutes)"
          name="preparation_time"
          variant="outlined"
          margin="normal"
          fullWidth
          value={recipe.preparation_time}
          onChange={handleInputChange}
        />
        <TextField
          label="Cooking Time (minutes)"
          name="cooking_time"
          variant="outlined"
          margin="normal"
          fullWidth
          value={recipe.cooking_time}
          onChange={handleInputChange}
        />
        <TextField
          label="Servings"
          name="servings"
          variant="outlined"
          margin="normal"
          fullWidth
          value={recipe.servings}
          onChange={handleInputChange}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Difficulty Level</InputLabel>
          <Select
            label="Difficulty Level"
            name="difficulty_level"
            value={recipe.difficulty_level}
            onChange={handleInputChange}
          >
            <MenuItem value="Easy">Easy</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="Hard">Hard</MenuItem>
          </Select>
        </FormControl>

        <Typography variant="h6" gutterBottom>
          Instructions
        </Typography>
        <ReactQuill
          value={recipe.instructions}
          onChange={handleInstructionsChange}
          modules={{
            toolbar: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
              ['link', 'image', 'video'],
              ['clean']
            ],
          }}
          style={{ height: '300px', marginBottom: '50px' }}
        />

        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <Avatar
            src={imagePreview}
            alt="Recipe Image"
            sx={{ width: 100, height: 100, mx: 'auto' }}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="image-upload"
            type="file"
            onChange={handleImageUpload}
          />
          <label htmlFor="image-upload">
            <IconButton color="primary" aria-label="upload picture" component="span">
              <CloudUploadIcon />
            </IconButton>
          </label>
        </Box>

        <Typography variant="h6" gutterBottom>
          Products
        </Typography>
        {recipe.recipe_products.map((product, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Product</InputLabel>
              <Select
                label="Product"
                name="product_id"
                value={product.product_id}
                onChange={(e) => handleProductChange(index, e)}
                required
              >
                {productList.map((prod) => (
                  <MenuItem key={prod.product_id} value={prod.product_id}>
                    {prod.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Quantity"
              name="quantity"
              variant="outlined"
              margin="normal"
              fullWidth
              value={product.quantity}
              onChange={(e) => handleProductChange(index, e)}
              required
            />
            <TextField
              label="Unit of Measure"
              name="unit_of_measure"
              variant="outlined"
              margin="normal"
              fullWidth
              value={product.unit_of_measure}
              onChange={(e) => handleProductChange(index, e)}
            />
            <IconButton color="secondary" onClick={() => removeProduct(index)}>
              <RemoveCircle />
            </IconButton>
          </Box>
        ))}
        <Button variant="outlined" color="primary" onClick={addProduct} startIcon={<AddCircle />} sx={{ mt: 2 }}>
          Add Product
        </Button>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {id ? 'Update Recipe' : 'Add Recipe'}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" color="secondary" fullWidth onClick={handleBack}>
              Back
            </Button>
          </Grid>
        </Grid>

      </form>
    </Box>
  );
};

export default RecipeForm;

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

const AuthContext = createContext();

const API_URL = process.env.REACT_APP_API_URL;

const http = axios.create({
  baseURL: API_URL,
});

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: localStorage.getItem('token'),
    user: localStorage.getItem('token') ? jwt_decode(localStorage.getItem('token')) : null,
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwt_decode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        logout();
      } else {
        const timeout = decodedToken.exp * 1000 - Date.now() - 60000; 
        setTimeout(refreshToken, timeout);
      }
    }
  }, []);

  const refreshToken = async () => {
    try {
      const response = await http.post('/auth/refresh-token');
      const newToken = response.data.token;
      localStorage.setItem('token', newToken);
      setAuthState({
        token: newToken,
        user: jwt_decode(newToken),
      });
    } catch (error) {
      logout();
    }
  };

  const login = async (email, password) => {
      const response = await http.post('/auth/login', { email, password });
      const token = response.data.token;
      localStorage.setItem('token', token);
      setAuthState({
        token,
        user: jwt_decode(token),
      });
      // Set up the token refresh timer
      const decodedToken = jwt_decode(token);
      const timeout = decodedToken.exp * 1000 - Date.now() - 60000; // 60 seconds before expiration
      setTimeout(refreshToken, timeout);
  };

  const register = async (email, password, firstName, lastName) => {
      const response = await http.post('/auth/register', {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
      });
      const token = response.data.token;
      localStorage.setItem('token', token);
      setAuthState({
        token,
        user: jwt_decode(token),
      });
      // Set up the token refresh timer
      const decodedToken = jwt_decode(token);
      const timeout = decodedToken.exp * 1000 - Date.now() - 60000; // 60 seconds before expiration
      setTimeout(refreshToken, timeout);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setAuthState({ token: null, user: null });
  };

  return (
    <AuthContext.Provider value={{ authState, login, register, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Navbar from './components/Navbar';
import Dashboard from './components/Dashboard';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import ProductList from './components/Products/ProductList';
import ProductForm from './components/Products/ProductForm';
import RecipeList from './components/Recipes/RecipeList';
import RecipeForm from './components/Recipes/RecipeForm';
import ScanImage from './components/ScanImage/ScanImage';
import UserList from './components/Users/UserList';
import UserForm from './components/Users/UserForm';
import OrderList from './components/Orders/OrderList';
import OrderForm from './components/Orders/OrderForm';
import ProductDeck from './components/Products/ProductDeck';
import RecipeDeck from './components/Recipes/RecipeDeck';

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      // Prevent the mini-infobar from appearing
      e.preventDefault();
      // Store the event for later use
      setDeferredPrompt(e);
      // Display your custom install button
      setShowInstallPrompt(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null); // Reset the deferred prompt after showing it
      });
    }
    setShowInstallPrompt(false); // Hide the custom install button after prompt
  };

  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/products/new" element={<ProductForm />} />
          <Route path="/products/edit/:id" element={<ProductForm />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/recipes/new" element={<RecipeForm />} />
          <Route path="/recipes/edit/:id" element={<RecipeForm />} />
          <Route path="/recipes" element={<RecipeList />} />
          <Route path="/orders" element={<OrderList />} />
          <Route path="/orders/new" element={<OrderForm isReadOnly={false} />} />
          <Route path="/orders/edit/:id" element={<OrderForm isReadOnly={false} />} />
          <Route path="/orders/view/:id" element={<OrderForm isReadOnly={true} />} />

          <Route path="/users/new" element={<UserForm />} />
          <Route path="/users/edit/:id" element={<UserForm />} />
          <Route path="/users" element={<UserList />} />
          <Route path="/scan-image" element={<ScanImage />} />

          <Route path="/product-deck" element={<ProductDeck />} />
          <Route path="/recipe-deck" element={<RecipeDeck />} />
          <Route path="/order-deck" element={<OrderList isReadOnly={true} />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        {/* Custom "Add to Home Screen" button */}
        {showInstallPrompt && (
          <button onClick={handleInstallClick} style={{ position: 'fixed', bottom: '20px', right: '20px', padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px' }}>
            Add to Home Screen
          </button>
        )}
      </Router>
    </AuthProvider>
  );
}

export default App;

import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Box, Button, Typography, Card, CardContent, CardActions, CardMedia, Grid,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, Badge, List, ListItem, ListItemText, Divider, CircularProgress, Backdrop
} from '@mui/material';
import { AddShoppingCart, CameraAlt, ShoppingCart, RemoveCircleOutline, AddCircleOutline, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import http from '../../services/httpService';
import { marked } from 'marked';  // Corrected import statement

const ProductDeck = () => {
  const { authState } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [openCamera, setOpenCamera] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // State for loader
  const [openResultDialog, setOpenResultDialog] = useState(false); // State for result dialog
  const [freshnessScore, setFreshnessScore] = useState(''); // State for freshness score result (HTML)
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    updateTotal();
  }, [cart]);

  const fetchProducts = async () => {
    try {
      const response = await http.get('/products');
      setProducts(response.data);
    } catch (error) {
      console.error('Failed to fetch products', error);
    }
  };

  const updateTotal = () => {
    const newTotal = cart.reduce((acc, item) => acc + item.quantity * item.price, 0);
    setTotal(newTotal);
  };

  const handleOpenCamera = (product) => {
    setSelectedProduct(product);
    setOpenCamera(true);
    startCamera();
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
      videoRef.current.srcObject = stream;
    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  };

  const captureImage = () => {
    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
    canvasRef.current.toBlob((blob) => {
      sendImageToBackend(blob);
    }, 'image/png');
    stopCamera();
  };

  const sendImageToBackend = async (imageBlob) => {
    try {
      setIsLoading(true); // Show loader
      const formData = new FormData();
      formData.append('image', imageBlob, 'captured_image.png');
      formData.append('name', selectedProduct.name);

      const response = await http.post('/products/score-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Convert markdown to HTML and set it to the state
      const markdown = response.data.score;
      const htmlContent = marked(markdown);
      setFreshnessScore(htmlContent); // Set HTML content
      setOpenResultDialog(true); // Open result dialog
    } catch (error) {
      console.error('Failed to send image to backend', error);
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  const stopCamera = () => {
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    videoRef.current.srcObject = null;
    setOpenCamera(false);
  };

  const addToCart = (product) => {
    setCart(prevCart => {
      const existingItem = prevCart.find(item => item.product_id === product.product_id);
      if (existingItem) {
        return prevCart.map(item =>
          item.product_id === product.product_id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  };

  const handleQuantityChange = (product_id, delta) => {
    setCart(prevCart =>
      prevCart.map(item =>
        item.product_id === product_id
          ? { ...item, quantity: Math.max(1, item.quantity + delta) }
          : item
      )
    );
  };

  const handleRemoveFromCart = (product_id) => {
    setCart(prevCart => prevCart.filter(item => item.product_id !== product_id));
  };

  const handleOpenCart = () => {
    setOpenCart(true);
  };

  const handleCloseCart = () => {
    setOpenCart(false);
  };

  const handleCheckout = () => {
    handleCloseCart();
    navigate('/orders/new', { state: { cart } });
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - 240px)` },
        ml: { sm: `240px` },
      }}
    >
      <Typography 
                variant="h5" 
                gutterBottom 
                sx={{ 
                  color: '#008080',
                  fontWeight: 'bold',
                  textTransform: 'uppercase'
                }}
            >
                 Product List
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>

        <TextField
          label="Search Products"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <IconButton color="primary" onClick={handleOpenCart}>
          <Badge badgeContent={cart.length} color="secondary">
            <ShoppingCart />
          </Badge>
        </IconButton>
      </Box>

      <Grid container spacing={3}>
        {filteredProducts.map((product) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={product.product_id}>
            <Card sx={{ maxWidth: 345, mx: 'auto', my: 2 }}>
              <CardMedia
                component="img"
                height="140"
                image={product.image_url || 'https://via.placeholder.com/140'}
                alt={product.name}
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {product.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Price: ${product.price}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Stock: {product.stock_quantity}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="outlined" color="primary"
                  size="small"
                  startIcon={<AddShoppingCart />}
                  onClick={() => addToCart(product)}
                >
                  Add to Cart
                </Button>
                <Button
                  variant="outlined" color="secondary"
                  size="small"
                  startIcon={<CameraAlt />}
                  onClick={() => handleOpenCamera(product)}
                >
                  Take Picture
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Loader */}
      <Backdrop open={isLoading} sx={{ zIndex: 1300, color: '#fff' }}>
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Analyzing Image...
          </Typography>
        </Box>
      </Backdrop>

      {/* Cart Dialog */}
      <Dialog
        open={openCart}
        onClose={handleCloseCart}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: { minWidth: '400px' }
        }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Your Cart</Typography>
            <IconButton onClick={handleCloseCart}>
              <Badge badgeContent={cart.length} color="secondary">
                <ShoppingCart />
              </Badge>
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {cart.length === 0 ? (
            <Typography>No items in cart</Typography>
          ) : (
            <List>
              {cart.map((item, index) => (
                <React.Fragment key={item.product_id}>
                  <ListItem>
                    <CardMedia
                      component="img"
                      sx={{ width: 50, height: 50, mr: 2 }}
                      image={item.image_url || 'https://via.placeholder.com/50'}
                      alt={item.name}
                    />
                    <ListItemText
                      primary={`${item.name} - $${item.price}`}
                      secondary={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                              size="small"
                              onClick={() => handleQuantityChange(item.product_id, -1)}
                            >
                              <RemoveCircleOutline />
                            </IconButton>
                            <TextField
                              type="number"
                              value={item.quantity}
                              sx={{ width: 50, mx: 1 }}
                              InputProps={{ readOnly: true }}
                            />
                            <IconButton
                              size="small"
                              onClick={() => handleQuantityChange(item.product_id, 1)}
                            >
                              <AddCircleOutline />
                            </IconButton>
                          </Box>
                          <Typography variant="body2">
                            Subtotal: ${(item.quantity * item.price).toFixed(2)}
                          </Typography>
                        </Box>
                      }
                    />
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveFromCart(item.product_id)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItem>
                  {index < cart.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Typography variant="h6" sx={{ mr: 2 }}>Total: ${total.toFixed(2)}</Typography>
          <Button onClick={handleCloseCart} color="primary">
            Close
          </Button>
          <Button onClick={handleCheckout} color="secondary" disabled={cart.length === 0}>
            Checkout
          </Button>
        </DialogActions>
      </Dialog>

      {/* Camera Dialog */}
      <Dialog open={openCamera} onClose={stopCamera}>
        <DialogTitle>Capture Image for {selectedProduct?.name}</DialogTitle>
        <DialogContent>
          <video ref={videoRef} autoPlay style={{ width: '100%' }} />
          <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480"></canvas>
        </DialogContent>
        <DialogActions>
          <Button onClick={stopCamera} color="primary">
            Cancel
          </Button>
          <Button onClick={captureImage} color="secondary">
            Capture
          </Button>
        </DialogActions>
      </Dialog>

      {/* Result Dialog */}
      <Dialog open={openResultDialog} onClose={() => setOpenResultDialog(false)}>
        <DialogTitle>Freshness Score</DialogTitle>
        <DialogContent>
          {/* Render the freshness score as HTML */}
          <div dangerouslySetInnerHTML={{ __html: freshnessScore }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenResultDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProductDeck;

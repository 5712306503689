// src/components/ScanImage/ScanImage.js
import React, { useState, useRef } from 'react';
import { Container, Typography, Button, CircularProgress, TextField } from '@mui/material';
import Webcam from 'react-webcam';
import { scanImageForShelfLife } from '../../services/imageRecognitionService';

const ScanImage = () => {
  const [imageFile, setImageFile] = useState(null);
  const [shelfLifeInfo, setShelfLifeInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [useCamera, setUseCamera] = useState(false);

  const webcamRef = useRef(null);

  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageFile(imageSrc);
    setUseCamera(false);
  };

  const handleScanImage = async () => {
    if (!imageFile) {
      setError('Please select or capture an image.');
      return;
    }

    setLoading(true);
    setError('');
    setShelfLifeInfo(null);

    try {
      const data = await scanImageForShelfLife(imageFile);
      setShelfLifeInfo(data);
    } catch (err) {
      setError('Failed to scan the image. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Scan Image for Shelf Life
      </Typography>

      {useCamera ? (
        <div>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            style={{ width: '100%', height: 'auto', marginBottom: '20px' }}
          />
          <Button variant="contained" color="primary" onClick={handleCapture}>
            Capture Photo
          </Button>
        </div>
      ) : (
        <div>
          <input
            accept="image/*"
            type="file"
            onChange={handleFileChange}
            style={{ marginBottom: '20px' }}
          />
          <Button variant="contained" color="primary" onClick={() => setUseCamera(true)} style={{ marginRight: '10px' }}>
            Use Camera
          </Button>
          <Button variant="contained" color="primary" onClick={handleScanImage}>
            Scan Image
          </Button>
        </div>
      )}

      {loading && <CircularProgress style={{ marginTop: '20px' }} />}
      {error && <Typography color="error" style={{ marginTop: '20px' }}>{error}</Typography>}
      {shelfLifeInfo && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6">Shelf Life Information:</Typography>
          <TextField
            label="Best Shelf Life (Days)"
            value={shelfLifeInfo.best_shelf_life_days || ''}
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            label="Average Shelf Life (Days)"
            value={shelfLifeInfo.average_shelf_life_days || ''}
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            label="Least Shelf Life (Days)"
            value={shelfLifeInfo.least_shelf_life_days || ''}
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
      )}
    </Container>
  );
};

export default ScanImage;

import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Box, Typography, MenuItem, Grid, Avatar, IconButton } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import http from '../../services/httpService';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

const UserForm = () => {
  const { authState } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    date_of_birth: '',
    role_id: '',
    profile_picture_url: '',
  });
  const [roles, setRoles] = useState([]);
  const [errors, setErrors] = useState({});
  const [profilePicture, setProfilePicture] = useState(null);

  useEffect(() => {
    if (id) {
      fetchUser();
    }
    fetchRoles();
  }, [id]);

  const fetchUser = async () => {
    try {
      const response = await http.get(`/users/${id}`);
      setUser(response.data);
      if (response.data.profile_picture_url) {
        setProfilePicture(response.data.profile_picture_url);
      }
    } catch (error) {
      console.error('Failed to fetch user', error);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await http.get('/roles');
      setRoles(response.data);
    } catch (error) {
      console.error('Failed to fetch roles', error);
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const validate = () => {
    let tempErrors = {};
    if (!user.first_name) tempErrors.first_name = 'First Name is required';
    if (!user.last_name) tempErrors.last_name = 'Last Name is required';
    if (!user.email) tempErrors.email = 'Email is required';
    if (!user.role_id) tempErrors.role_id = 'Role is required';

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result);
        setUser({ ...user, profile_picture_url: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      if (id) {
        await http.put(`/users/${id}`, user);
      } else {
        await http.post('/users', user);
      }
      navigate('/users');
    } catch (error) {
      console.error('Failed to save user', error);
    }
  };

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - 240px)` }, // Adjust width for larger screens
        ml: { sm: `240px` }, // Margin left for larger screens to accommodate the drawer
      }}
    >
      <Typography 
          variant="h5" 
          gutterBottom 
          sx={{ 
            color: '#008080',
            fontWeight: 'bold',
            textTransform: 'uppercase'
          }}
        >
          {id ? 'Edit User' : 'Add User'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="First Name"
          name="first_name"
          variant="outlined"
          margin="normal"
          fullWidth
          value={user.first_name}
          onChange={handleChange}
          error={!!errors.first_name}
          helperText={errors.first_name}
        />
        <TextField
          label="Last Name"
          name="last_name"
          variant="outlined"
          margin="normal"
          fullWidth
          value={user.last_name}
          onChange={handleChange}
          error={!!errors.last_name}
          helperText={errors.last_name}
        />
        <TextField
          label="Email"
          name="email"
          variant="outlined"
          margin="normal"
          fullWidth
          value={user.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          label="Date of Birth"
          name="date_of_birth"
          variant="outlined"
          margin="normal"
          type="date"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={user.date_of_birth}
          onChange={handleChange}
        />
        <TextField
          select
          label="Role"
          name="role_id"
          variant="outlined"
          margin="normal"
          fullWidth
          value={user.role_id}
          onChange={handleChange}
          error={!!errors.role_id}
          helperText={errors.role_id}
        >
          {roles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </TextField>

        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <Avatar
            src={profilePicture}
            alt="Profile Picture"
            sx={{ width: 100, height: 100, mx: 'auto' }}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="profile-picture-upload"
            type="file"
            onChange={handleProfilePictureChange}
          />
          <label htmlFor="profile-picture-upload">
            <IconButton color="primary" aria-label="upload picture" component="span">
              <PhotoCamera />
            </IconButton>
          </label>
        </Box>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {id ? 'Update User' : 'Add User'}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" color="secondary" fullWidth onClick={handleBack}>
              Back
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default UserForm;

import React, { useContext, useState } from 'react';
import { TextField, Button, Container, Typography, Box, Paper, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, Link } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import ReCAPTCHA from 'react-google-recaptcha';

const Login = () => {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) return;

    try {
      await login(email, password);
      // Redirect to products page
      navigate('/');
    } catch (error) {
      //console.error('Login failed', error);
      setLoginError('Login failed. Please check your email and password.');
    }
  };

  const validate = () => {
    let isValid = true;

    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (!password) {
      setPasswordError('Password is required.');
      isValid = false;
    } else {
      setPasswordError('');
    }

    if (false && !captchaValue) {
      alert('Please complete the CAPTCHA.');
      isValid = false;
    }

    return isValid;
  };

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
  };

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <Container maxWidth="xs">
      <Paper 
        sx={{
          padding: 3,
          backgroundColor: 'white',
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 3,
          }}
        >
          <img
            src="https://www.detectfresh.com/images/DetectFresh.png"
            alt="Main Logo"
            style={{ height: '80px', objectFit: 'contain'}}
          />
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            variant="outlined"
            margin="normal"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
          />
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            margin="normal"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPasswordToggle}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          
          {/*<Box textAlign="center" sx={{ marginTop: 2 }}>
            <ReCAPTCHA
              sitekey="YOUR_GOOGLE_RECAPTCHA_SITE_KEY"  // Replace with your actual site key
              onChange={onCaptchaChange}
            />
          </Box>*/}
          {loginError && (
            <Typography color="error" variant="body2" align="center" sx={{ marginTop: 2 }}>
              {loginError}
            </Typography>
          )}
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Login
          </Button>
          
          {/*<Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={handleGoogleLogin}
            sx={{ marginTop: '10px' }}
          >
            Login with Google
          </Button>*/}
        </form>
        <Box textAlign="center" sx={{ marginTop: 2 }}>
          <Typography variant="body2">Don't have an account?</Typography>
          <Button
            component={Link}
            to="/register"
            variant="text"
            color="primary"
            fullWidth
          >
            Register
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;

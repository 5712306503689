import React, { useContext, useState } from 'react';
import { TextField, Button, Container, Typography, Box, Paper, IconButton, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ReCAPTCHA from 'react-google-recaptcha';

const Register = () => {
  const { register } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (false && !captchaValue) {
      alert('Please complete the CAPTCHA');
      return;
    }
    try {
      await register(email, password, firstName, lastName);
      // Redirect to products page
      navigate('/');
    } catch (error) {
      console.error('Registration failed', error);
    }
  };

  const handleGoogleRegister = () => {
    // Redirect to Google OAuth endpoint for registration
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
  };

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <Container maxWidth="xs">
      <Paper 
        sx={{
          padding: 3,
          backgroundColor: 'white',
          borderRadius: 2,
          boxShadow: 3,
          textAlign: 'center',
        }}
      >
        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 3, // Adjust this value to control the space between the logo and form
          }}
        >
          <img
            src="https://www.detectfresh.com/images/DetectFresh.png"
            alt="Main Logo"
            style={{ height: '80px', objectFit: 'contain'}}
          />
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            label="First Name"
            variant="outlined"
            margin="normal"
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <TextField
            label="Last Name"
            variant="outlined"
            margin="normal"
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <TextField
            label="Email"
            variant="outlined"
            margin="normal"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            margin="normal"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* reCAPTCHA Section 
          <Box sx={{ margin: '16px 0', textAlign: 'center' }}>
            <ReCAPTCHA
              sitekey="YOUR_GOOGLE_RECAPTCHA_SITE_KEY"
              onChange={handleCaptchaChange}
            />
          </Box>
          */}

          <Button type="submit" variant="contained" color="primary" fullWidth>
            Register
          </Button>
        </form>

        <Box textAlign="center" sx={{ marginTop: 2 }}>
          <Typography variant="body2">Or</Typography>
          {/*
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={handleGoogleRegister}
            sx={{ marginTop: '10px' }}
          >
            Register with Google
          </Button>
          */}
          <Button
            variant="text"
            color="primary"
            fullWidth
            onClick={handleBack}
            sx={{ marginTop: '10px' }}
          >
            Back
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Register;

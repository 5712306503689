import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Box, Typography, Grid, Avatar, IconButton } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import http from '../../services/httpService';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const ProductForm = () => {
  const { authState } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    name: '',
    description: '',
    category: '',
    price: '',
    stock_quantity: '',
    unit_of_measure: '',
    best_shelf_life_days: '',
    best_shelf_life_conditions: '',
    average_shelf_life_days: '',
    average_shelf_life_conditions: '',
    least_shelf_life_days: '',
    least_shelf_life_conditions: '',
  });
  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // New state for the selected image file

  useEffect(() => {
    if (id) {
      fetchProduct();
    }
  }, [id]);

  const fetchProduct = async () => {
    try {
      const response = await http.get(`/products/${id}`);
      setProduct(response.data);
      if (response.data.image_url) {
        setImagePreview(response.data.image_url);
      }
    } catch (error) {
      console.error('Failed to fetch product', error);
    }
  };

  const handleChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file); // Save the selected image file for form submission
      setImagePreview(URL.createObjectURL(file)); // Show image preview
    }
  };

  const validate = () => {
    let tempErrors = {};
    if (!product.name) tempErrors.name = 'Name is required';

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const formData = new FormData();
    for (const key in product) {
      formData.append(key, product[key]);
    }

    if (selectedImage) {
      formData.append('image', selectedImage); // Append image file to FormData
    }
    
    try {
      if (id) {
        await http.put(`/products/${id}`, formData);
      } else {
        await http.post('/products', formData);
      }
      navigate('/products');
    } catch (error) {
      console.error('Failed to save product', error);
    }
  };

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - 240px)` }, // Adjust width for larger screens
        ml: { sm: `240px` }, // Margin left for larger screens to accommodate the drawer
      }}
    >
      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ 
          color: '#008080',
          fontWeight: 'bold',
          textTransform: 'uppercase'
        }}
      >
        {id ? 'Edit Product' : 'Add Product'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          name="name"
          variant="outlined"
          margin="normal"
          fullWidth
          value={product.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          label="Description"
          name="description"
          variant="outlined"
          margin="normal"
          multiline
          rows={4}
          fullWidth
          value={product.description}
          onChange={handleChange}
        />
        <TextField
          label="Category"
          name="category"
          variant="outlined"
          margin="normal"
          fullWidth
          value={product.category}
          onChange={handleChange}
        />
        <TextField
          label="Price"
          name="price"
          type="number"
          variant="outlined"
          margin="normal"
          fullWidth
          value={product.price}
          onChange={handleChange}
        />
        <TextField
          label="Stock Quantity"
          name="stock_quantity"
          type="number"
          variant="outlined"
          margin="normal"
          fullWidth
          value={product.stock_quantity}
          onChange={handleChange}
        />
        <TextField
          label="Unit of Measure"
          name="unit_of_measure"
          variant="outlined"
          margin="normal"
          fullWidth
          value={product.unit_of_measure}
          onChange={handleChange}
        />

        <TextField
          label="Best Shelf Life (Days)"
          name="best_shelf_life_days"
          type="number"
          variant="outlined"
          margin="normal"
          fullWidth
          value={product.best_shelf_life_days}
          onChange={handleChange}
        />
        <TextField
          label="Best Shelf Life Conditions"
          name="best_shelf_life_conditions"
          variant="outlined"
          margin="normal"
          fullWidth
          value={product.best_shelf_life_conditions}
          onChange={handleChange}
        />
        <TextField
          label="Average Shelf Life (Days)"
          name="average_shelf_life_days"
          type="number"
          variant="outlined"
          margin="normal"
          fullWidth
          value={product.average_shelf_life_days}
          onChange={handleChange}
        />
        <TextField
          label="Average Shelf Life Conditions"
          name="average_shelf_life_conditions"
          variant="outlined"
          margin="normal"
          fullWidth
          value={product.average_shelf_life_conditions}
          onChange={handleChange}
        />
        <TextField
          label="Least Shelf Life (Days)"
          name="least_shelf_life_days"
          type="number"
          variant="outlined"
          margin="normal"
          fullWidth
          value={product.least_shelf_life_days}
          onChange={handleChange}
        />
        <TextField
          label="Least Shelf Life Conditions"
          name="least_shelf_life_conditions"
          variant="outlined"
          margin="normal"
          fullWidth
          value={product.least_shelf_life_conditions}
          onChange={handleChange}
        />

        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <Avatar
            src={imagePreview}
            alt="Product Image"
            sx={{ width: 100, height: 100, mx: 'auto' }}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="image-upload"
            type="file"
            onChange={handleImageUpload}
          />
          <label htmlFor="image-upload">
            <IconButton color="primary" aria-label="upload picture" component="span">
              <CloudUploadIcon />
            </IconButton>
          </label>
        </Box>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {id ? 'Update Product' : 'Add Product'}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" color="secondary" fullWidth onClick={handleBack}>
              Back
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ProductForm;

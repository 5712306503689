import React, { useContext, useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, Divider, Box, ListItemIcon } from '@mui/material';
import { Menu as MenuIcon, AccountCircle, Dashboard as DashboardIcon, ShoppingCart as ShoppingCartIcon, Receipt as ReceiptIcon, People as PeopleIcon, Logout as LogoutIcon, ListAlt as OrdersIcon } from '@mui/icons-material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import jwt_decode from 'jwt-decode';

const Navbar = () => {
  const { authState, logout } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isLoginPage = ['/login', '/register', '/logout'].indexOf(location.pathname) !== -1;

  useEffect(() => {
    if (!isLoginPage && !authState.token) {
      navigate('/login');
    }
  }, [authState.token, navigate, isLoginPage]);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  let menuItems = [
    { text: 'Dashboard', link: '/', icon: <DashboardIcon /> },
    { text: 'Products', link: '/products', icon: <ShoppingCartIcon /> },
    { text: 'Recipes', link: '/recipes', icon: <ReceiptIcon /> },
    { text: 'Users', link: '/users', icon: <PeopleIcon /> },
    { text: 'Orders', link: '/orders', icon: <OrdersIcon /> }, // New Orders menu item
  ];

  if(authState.token){
    const decodedToken = jwt_decode(authState.token);
    let roleID = String(decodedToken.role_id);

    if(roleID === "4"){
      menuItems = [
        { text: 'Dashboard', link: '/', icon: <DashboardIcon /> },
        { text: 'Products', link: '/product-deck', icon: <ShoppingCartIcon /> },
        { text: 'Recipes', link: '/recipe-deck', icon: <ReceiptIcon /> },
        { text: 'Orders', link: '/order-deck', icon: <OrdersIcon /> }
      ];
    }
  }

  const commonHeight = '64px';  // Define a common height for the toolbar and logo

  const drawer = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box
        sx={{
          display: { xs: 'none', sm: 'block' },
          textAlign: 'center',
          padding: '8px 0',
          height: commonHeight, // Set height for the logo container
        }}
      >
        <img
          src="https://www.detectfresh.com/images/DetectFresh.png"
          alt="Client Logo"
          style={{ height: '100%', maxWidth: '100%', objectFit: 'contain' }}
        />
      </Box>
      <Divider />
      <List>
        {menuItems.map((item, index) => (
          <ListItem 
            button 
            component={Link} 
            to={item.link} 
            key={index}
            selected={location.pathname === item.link} // Highlight the active menu
            sx={{
              backgroundColor: location.pathname === item.link ? '#e0f7fa' : 'transparent', // Optional additional styling
              '&:hover': {
                backgroundColor: '#b2ebf2',
              }
            }}
          >
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Show main logo only on the login page */}
      {isLoginPage && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px 0',
          }}
        >
        </Box>
      )}

      {/* Thin toolbar for large screens, hide on login */}
      {!isLoginPage && (
        <AppBar
          position="static"
          sx={{
            display: { xs: 'none', sm: 'block' },
            backgroundColor: '#669FAE',
            height: commonHeight, // Set height for the top toolbar
          }}
        />
      )}

      {/* Permanent drawer on larger screens, hide on login */}
      {!isLoginPage && (
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            width: 240,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
          }}
        >
          {drawer}
        </Drawer>
      )}

      {/* AppBar for small screens, hide on login */}
      {!isLoginPage && (
        <AppBar position="static" sx={{ display: { xs: 'block', sm: 'none', backgroundColor: '#669FAE' } }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component={Link} to="/" style={{ flexGrow: 1, textDecoration: 'none', color: 'inherit' }}>
              DetectFresh
            </Typography>
            <div>
              {authState.isAuthenticated && (
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleLogout}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              )}
            </div>
          </Toolbar>
        </AppBar>
      )}

      {/* Drawer for small screens, hide on login */}
      {!isLoginPage && (
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          sx={{ display: { xs: 'block', sm: 'none' } }}
        >
          {drawer}
        </Drawer>
      )}

      {/* Main content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: !isLoginPage ? `calc(100% - 240px)` : '100%' },
          marginLeft: { sm: !isLoginPage ? `240px` : '0px' },
        }}
      >
        {/* Your main content goes here */}
      </Box>
    </Box>
  );
};

export default Navbar;

import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { Edit, Delete, Info } from '@mui/icons-material';
import AuthContext from '../../context/AuthContext';
import http from '../../services/httpService';

const RecipeDeck = () => {
  const { authState } = useContext(AuthContext);
  const [recipes, setRecipes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  useEffect(() => {
    fetchRecipes();
  }, []);

  const fetchRecipes = async () => {
    try {
      const response = await http.get('/recipes');
      setRecipes(response.data);
    } catch (error) {
      console.error('Failed to fetch recipes', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await http.delete(`/recipes/${id}`);
      fetchRecipes();
    } catch (error) {
      console.error('Failed to delete recipe', error);
    }
  };

  const handleCardClick = (recipe) => {
    setSelectedRecipe(recipe);
  };

  const handleClose = () => {
    setSelectedRecipe(null);
  };

  const filteredRecipes = recipes.filter((recipe) =>
    recipe.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - 240px)` },
        ml: { sm: `240px` },
      }}
    >
      <Typography 
          variant="h5" 
          gutterBottom 
          sx={{ 
            color: '#008080',
            fontWeight: 'bold',
            textTransform: 'uppercase'
          }}
        >
           Recipe List
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        
        <TextField
          label="Search Recipes"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>

      <Grid container spacing={3}>
        {filteredRecipes.map((recipe) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={recipe.recipe_id}>
            <Card sx={{ maxWidth: 345, mx: 'auto', my: 2 }} onClick={() => handleCardClick(recipe)}>
              <CardMedia
                component="img"
                height="140"
                image={recipe.image_url || 'https://via.placeholder.com/140'}
                alt={recipe.title}
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {recipe.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {recipe.description.substring(0, 60)}...
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog to show recipe details */}
      {selectedRecipe && (
        <Dialog open={true} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>{selectedRecipe.title}</DialogTitle>
          <DialogContent>
            <img
              src={selectedRecipe.image_url || 'https://via.placeholder.com/600'}
              alt={selectedRecipe.title}
              style={{ width: '100%', height: 'auto', marginBottom: '20px' }}
            />
            <Typography variant="h6">Description</Typography>
            <Typography variant="body1" paragraph>
              {selectedRecipe.description}
            </Typography>
            <Typography variant="h6">Preparation Time</Typography>
            <Typography variant="body1" paragraph>
              {selectedRecipe.preparation_time} mins
            </Typography>
            <Typography variant="h6">Cooking Time</Typography>
            <Typography variant="body1" paragraph>
              {selectedRecipe.cooking_time} mins
            </Typography>
            <Typography variant="h6">Servings</Typography>
            <Typography variant="body1" paragraph>
              {selectedRecipe.servings}
            </Typography>
            <Typography variant="h6">Instructions</Typography>
            <Typography variant="body1" paragraph dangerouslySetInnerHTML={{ __html: selectedRecipe.instructions }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default RecipeDeck;

import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import AuthContext from '../../context/AuthContext';
import http from '../../services/httpService';

const RecipeList = () => {
  const { authState } = useContext(AuthContext);
  const [recipes, setRecipes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchRecipes();
  }, []);

  const fetchRecipes = async () => {
    try {
      const response = await http.get('/recipes');
      setRecipes(response.data);
    } catch (error) {
      console.error('Failed to fetch recipes', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await http.delete(`/recipes/${id}`);
      fetchRecipes();
    } catch (error) {
      console.error('Failed to delete recipe', error);
    }
  };

  const filteredRecipes = recipes.filter((recipe) =>
    recipe.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - 240px)` }, // Adjust width for larger screens
        ml: { sm: `240px` }, // Margin left for larger screens to accommodate the drawer
      }}
    >
      <Typography 
          variant="h5" 
          gutterBottom 
          sx={{ 
            color: '#008080',
            fontWeight: 'bold',
            textTransform: 'uppercase'
          }}
        >
           Recipe List
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Button variant="contained" color="primary" href="/recipes/new">
          Add Recipe
        </Button>

        <TextField
          label="Search Recipes"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>
      
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell> {/* New column for image */}
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Preparation Time</TableCell>
              <TableCell>Cooking Time</TableCell>
              <TableCell>Servings</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRecipes.map((recipe) => (
              <TableRow key={recipe.recipe_id}>
                <TableCell>
                  <img 
                    src={recipe.image_url} 
                    alt={recipe.title} 
                    style={{ width: '50px', height: '50px', objectFit: 'cover' }} 
                  />
                </TableCell> {/* Displaying recipe image */}
                <TableCell>{recipe.title}</TableCell>
                <TableCell>{recipe.description}</TableCell>
                <TableCell>{recipe.preparation_time} mins</TableCell>
                <TableCell>{recipe.cooking_time} mins</TableCell>
                <TableCell>{recipe.servings}</TableCell>
                <TableCell>
                  <IconButton color="primary" href={`/recipes/edit/${recipe.recipe_id}`}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDelete(recipe.recipe_id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RecipeList;

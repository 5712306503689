import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const http = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor to automatically add the token
http.interceptors.request.use(
  async (config) => {
    // Retrieve the token using the helper function
    const token = localStorage.getItem('token')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          setAuthState({ token: null, user: null }); 
    }
    return Promise.reject(error);
  }
);

export default http;
